import React from 'react';
import SideBar from '../SideBar/SideBar';

const DashBord = () => {
    return (
        <div>
            <SideBar />
        </div>
    );
};

export default DashBord;